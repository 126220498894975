<template>
  <div class="mx-auto container container--fluid">
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar flat dense color="white">
      <v-toolbar-title>TPO Program</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-toolbar>
    <v-divider class="mx-4"></v-divider>
    <v-app id="inspire" class="">
      <template v-if="load == true">
        <v-data-table
          :headers="headers"
          :items="tpo_prg_list"
          class="elevation-1"
          :search="search"
        >
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-text-field
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
              >
              </v-text-field>
              <v-spacer></v-spacer>
              <span style="width: 20px"></span>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="indigo darken-4"
                    dark
                    class="mb-2"
                    v-on="on"
                    @click="iseditable = !iseditable"
                    >Add New TPO Program</v-btn
                  >
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row dense>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.name"
                            label="TPO Program"
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            dense
                            v-model="editedItem.display_name"
                            label="Display Name"
                            :rules="[(v) => !!v || 'required']"
                          ></v-text-field>
                          <span v-if="editedIndex == -1"></span>
                        </v-col>
                        <v-col>
                          <!-- <v-col cols="12" sm="12" md="12" v-if='editedIndex == -1'>
                                                    <v-autocomplete :items="program_list" v-model="editedItem.program" label="Select Program" :rules="[v => !!v || 'required']" multiple></v-autocomplete>
                                                </v-col>
                                                <v-col cols="12" sm="12" md="12" v-if='editedIndex != -1'>Program
                                                    <v-select dense v-model="editedItem.program" :items="program_list" item-value="id" persistent-hint  :rules="[v => !!v || 'required']" multiple></v-select>
                                                </v-col> -->
                          <input
                            type="hidden"
                            v-model="editedItem.id"
                            name="id"
                          />
                          <!-- <v-hidden v-model="editedItem.id" label="id"></v-hidden> -->
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6" sm="3" md="2">
                          <v-checkbox
                            v-model="editedItem.isactive"
                            color="success"
                            label="Active"
                          ></v-checkbox>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close"
                      >Cancel</v-btn
                    >
                    <v-btn color="primary darken-1" @click="save">Save</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <!-- <template v-slot:item.isactive="{ item }">
                        <span v-if="item.isactive">
                        <v-icon  class="mr-2  v-icon-size" color="success">mdi-check-circle-outline</v-icon>
                        </span>
                        <span v-else>
                        <v-icon  class="mr-2  v-icon-size" color="error">mdi-close-circle-outline</v-icon>
                        </span>
                    </template> -->
          <template v-slot:item.isactive="{ item }">
            <v-switch
              v-model="item.isactive"
              @change="activeswitch(item)"
              :labels="{ checked: 'On', unchecked: 'Off' }"
            ></v-switch>
          </template>
          <template v-slot:item.action="{ item }">
            <div>
              <v-avatar style="" size="25" class="edit-avatar">
                <v-icon class="edit-v-icon mr-2" @click="editItem(item)"
                  >edit</v-icon
                >
              </v-avatar>
              <v-btn text>
                <v-icon color="red" @click="deletefun(item.id)">
                  mdi-delete</v-icon
                ></v-btn
              >
            </div>
          </template>
        </v-data-table>
      </template>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    overlay: false,
    snackbar_msg: "",
    color: "",
    snackbar: false,
    iseditable: false,
    load: false,
    dialog: false,
    tpo_prg_list: null,
    program_list: null,

    search: "",
    headers: [
      {
        text: "Name",
        value: "name",
      },
      {
        text: "Display Name",
        value: "display_name",
      },
      // {
      //     text: "Program",
      //     value: "program"
      // },
      {
        text: "Active",
        align: "left",
        sortable: true,
        value: "isactive",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
    ],

    editedIndex: -1,
    editedItem: {
      id: 0,
      name: "",
      display_name: "",
      program: "",
      isactive: true,
    },
    defaultItem: {
      id: 0,
      name: "",
      display_name: "",
      program: "",
      isactive: true,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New TPO Program" : "Edit TPO Program";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    overlay(val) {
      val = () => {
        this.overlay = false;
      };
    },
  },
  mounted() {
    axios
      .post("/AdminMaster/addTPOProgram")
      .then((res) => {
        if (res.data.msg == "200") {
          this.tpo_prg_list = res.data.tpo_prg_list;
          this.program_list = res.data.program_list;
          this.load = true;
        } else {
        }
      })
      .catch((error) => {
        this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
        // window.console.log(error);
      });
  },

  methods: {
    initializeErrorMsgFalse() {
      this.errorMsg.addressType = false;
    },
    onLoad() {
      this.overlay = true;
      axios

        .post("/AdminMaster/addTPOProgram")

        .then((res) => {
          if (res.data.msg == "200") {
            //window.alert(res.data.msg)

            if ((this.load = true)) {
              this.overlay = false;
              this.tpo_prg_list = res.data.tpo_prg_list;
            }
          } else {
            window.console.log(res.data.msg);
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          //window.console.log(error);
        })
        .finally(() => {
          "use strict";
          var overlay = false;
        });
    },
    editItem(item) {
      this.iseditable = false;
      this.editedIndex = this.tpo_prg_list.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedIndex > -1) {
        //  console.log("edit.." + this.editedItem);
        this.iseditable = false;
        axios
          .post("/AdminMaster/editTPOProgram", this.editedItem)
          .then((res) => {
            if (res.data.status.code == "SUCCESS") {
              Object.assign(
                this.tpo_prg_list[this.editedIndex],
                this.editedItem
              );

              this.showSnackbar(
                "#4caf50",
                "TPO Program Updated Successfully..."
              ); // show snackbar on success
            } else if (res.data.status.code == "NA") {
              //alert(res.data.status.message)
              this.showSnackbar("#b71c1c", "TPO Program Already Present!!!"); // show snackbar on error
            }
            this.onLoad();
          })
          .catch((error) => {
            this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
            // window.console.log(error);
          });
      } else {
        axios
          .post("/AdminImport/saveTPOProgram", this.editedItem)
          .then((res) => {
            if (res.data.status.code == "SUCCESS") {
              this.tpo_prg_list.push(this.editedItem);
              this.showSnackbar(
                "#4caf50",
                "New TPO Program Added Successfully!!!"
              ); // show snackbar on success
            } else if (res.data.status.code == "NA") {
              //alert(res.data.status.message)
              this.showSnackbar("#b71c1c", "TPO Program Already Present!!!"); // show snackbar on error
            }
            this.onLoad();
          })
          .catch((error) => {
            window.console.log(error);
          });
      }
      this.close();
    },

    deletefun(item) {
      const data = {
        deleteitem: item,
      };
      axios
        .post("/AdminMaster/deleteTPOProgram", data)
        .then((res) => {
          if (res.data.msg == "200") {
            //  console.log("success");
            this.showSnackbar("#4caf50", "Deleted Successfully...");
            this.onLoad();
          } else {
            this.showSnackbar("#b71c1c", res.data.msg);
          }
          this.onLoad();
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
    activeswitch(item) {
      //console.log("abc");

      const data = {
        deleteitem: item.id,
      };
      // console.log(item.id);

      axios
        .post("/AdminMaster/activeadminprogramtpo", data)
        .then((res) => {
          // console.log("res.data");
          // console.log(res.data);
          if (res.data.msg == "200") {
            //  console.log("res.data");
            // console.log(res.data);
            // console.log("success");
            this.showSnackbar("#4caf50", res.data.message);
            this.onLoad();
          } else {
            //console.log("fail");
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.downbtn {
  float: right;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}
</style>
